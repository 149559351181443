import styled from "styled-components"
import is from "styled-is"
import { motion } from "framer-motion"

export const StandardContainer = styled.div`
  --marginTop: ${props => props.marginTop / 2 || 0}px;
  --marginBottom: ${props => props.marginBottom / 2 || 0}px;

  position: relative;
  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);
  /* Padding Modifiers */
  ${is("padH")`
     padding-left: ${props => props.theme.padding.hMobile};
     padding-right: ${props => props.theme.padding.hMobile};
  `}
  /* Wrapper Modifiers */
  ${is("wrappedStandard")`
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}
  ${is("wrappedNarrow")`
    max-width: ${props => props.theme.containerWidths.narrow};
    margin-left: auto;
    margin-right: auto;
  `}  

  @media ${props => props.theme.breakpoints.medium} {
    --marginTop: ${props => props.marginTop || 0}px;
    --marginBottom: ${props => props.marginBottom || 0}px;
    ${is("padH")`
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    `}
  }
`
// Motion Container
export const MotionContainer = styled(motion.div)`
  --marginTop: ${props => props.marginTop / 2 || 0}px;
  --marginBottom: ${props => props.marginBottom / 2 || 0}px;
  
  position: relative;
  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);
  /* Padding Modifiers */
  ${is("padH")`
     padding-left: ${props => props.theme.padding.hMobile};
     padding-right: ${props => props.theme.padding.hMobile};
  `}
  /* Wrapper Modifiers */
  ${is("wrappedStandard")`
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}
  ${is("wrappedNarrow")`
    max-width: ${props => props.theme.containerWidths.narrow};
    margin-left: auto;
    margin-right: auto;
  `}  

  @media ${props => props.theme.breakpoints.medium} {
    --marginTop: ${props => props.marginTop / 2 || 0}px;
    --marginBottom: ${props => props.marginBottom / 2 || 0}px; 
    ${is("padH")`
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    `}
  }
`

export const StandardGridContainer = styled.div`
  --marginTop: ${props => props.marginTop / 2 || 0}px;
  --marginBottom: ${props => props.marginBottom / 2 || 0}px;
  --standard-grid-spacer: ${props => props.theme.padding.hMobile};

  margin-top: var(--marginTop);
  margin-bottom: var(--marginBottom);

  display: grid;
  grid-template-columns: var(--standard-grid-spacer) repeat(12, 1fr) var(
      --standard-grid-spacer
    );
  @media ${props => props.theme.breakpoints.medium} {
    --standard-grid-spacer: ${props => props.theme.padding.hDesk};
    --marginTop: ${props => props.marginTop || 0}px;
    --marginBottom: ${props => props.marginBottom || 0}px;
  }
`
