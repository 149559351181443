import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Headroom from 'react-headroom';

// Hooks,Querys & State
import { useWindowSize } from '../../hooks';
import { useAppContext } from '../../state';
import { useSiteSettings } from '../../GraphQl/useSiteSettings';

// Common Components
import { StandardContainer, NavLink } from '../common';
import { MenuToggle } from './menuToggle';
import { MobileNav } from './mobileNav';

// Styles
const StyledSiteHeader = styled((props) => <Headroom {...props} />)`
  z-index: 99999 !important;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    width: 100%;
    z-index: 9999;
    background: ${(props) => (props.reverseHeader || props.navOpen ? 'transparent' : '#ffffff')};
    > nav {
      > a {
        margin-left: 2rem;
        color: ${(props) => (props.reverseHeader ? '#ffffff' : props.theme.colors.primary.two)};
        &.active {
          color: ${(props) => props.theme.colors.primary.one};
        }
      }
    }
  }
`;

const StyledHomeLink = styled(Link)`
  width: 150px;
  @media ${(props) => props.theme.breakpoints.medium} {
    width: 250px;
  }
`;

export const Header = () => {
  const { logo, logoReversed, mainNav } = useSiteSettings();
  const { navOpen, toggleMenu, reverseHeader, pinHeader, unpinHeader } = useAppContext();
  const windowSize = useWindowSize();
  return (
    <>
      <StyledSiteHeader
        reverseHeader={reverseHeader}
        navOpen={navOpen}
        onPin={pinHeader}
        onUnpin={unpinHeader}
      >
        <StandardContainer as="header" padH>
          <StyledHomeLink to="/">
            <Img fluid={reverseHeader || navOpen ? logoReversed.asset.fluid : logo.asset.fluid} />
          </StyledHomeLink>
          {windowSize.width >= 1024 && (
            <nav>
              {mainNav.map((item) => (
                <NavLink {...item} />
              ))}
            </nav>
          )}
          {windowSize.width < 1024 && (
            <MenuToggle isOpen={navOpen} onClick={toggleMenu} reverseHeader={reverseHeader} />
          )}
        </StandardContainer>
      </StyledSiteHeader>
      <MobileNav items={mainNav} />
    </>
  );
};
