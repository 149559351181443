import { useStaticQuery, graphql } from "gatsby"

export const useSiteSettings = () => {
  const { sanitySettings } = useStaticQuery(graphql`
    query {
      sanitySettings {
        companyName
        logo {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        logoReversed {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        mainNav {
          linkText
          pageReference {
            ... on SanityHomePage {
              _type
            }
            ... on SanityStandardPage {
              _type
              slug {
                current
              }
            }
            ... on SanitySystemElements {
              _type
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)
  return sanitySettings || {}
}
