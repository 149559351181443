export const siteTheme = {
  breakpoints: {
    ns: 'screen and (min-width: 480px)',
    medium: 'screen and (min-width: 480px)',
    large: 'screen and (min-width: 1024px)',
    xl: 'screen and (min-width: 1500px)',
  },
  colors: {
    primary: {
      one: '#632AFE',
      two: '#020430',
      three: '#ffffff',
    },
    secondary: {
      one: '#000000',
      two: '#F3EEFF',
      three: '#D6D6D6',
    },
  },
  typography: {
    family: {
      standard: 'proxima_novaregular',
      bold: 'proxima_novabold',
      heading: 'proxima_novasemibold',
    },
    sizes: {
      regular: 'calc(16px + (20 - 16) * ((100vw - 320px) / (1800 - 320)))',
      smallReg: 'calc(12px + (14 - 12) * ((100vw - 320px) / (1800 - 320)))',
      h1: 'calc(45px + (90 - 45) * ((100vw - 320px) / (1800 - 320)))',
      h2: 'calc(32px + (67 - 32) * ((100vw - 320px) / (1800 - 320)))',
      h3: 'calc(24px + (50 - 24) * ((100vw - 320px) / (1800 - 320)))',
      h4: 'calc(22px + (37 - 22) * ((100vw - 320px) / (1800 - 320)))',
      h5: 'calc(20px + (29 - 20) * ((100vw - 320px) / (1800 - 320)))',
      h6: 'calc(19px + (21 - 19) * ((100vw - 320px) / (1800 - 320)))',
    },
    lineHeights: {
      standard: '1.8',
      heading: '1.5',
    },
    letterSpacing: {
      standard: '0',
      nav: '0.81px',
    },
    measures: {
      large: '55em',
      medium: '45em',
      standard: '30em',
      narrowHeading: '16em',
      heading: '22em',
    },
    hardBreak: '2rem',
  },
  padding: {
    hMobile: '1.4rem',
    hDesk: '6vw',
    vMobile: '70px',
    vDesk: '6rem',
  },
  containerWidths: {
    narrow: '1140px',
    standard: '1270px',
  },
};
