import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  html {
      text-rendering: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* overflow-x: hidden; */
      box-sizing: border-box;
      font-family: ${(props) => props.theme.typography.family.standard};
      color: ${(props) => props.theme.colors.primary.two};
  }
  body {
    /* overflow-x: hidden; */
  }
  /* Minimal Resets */
 
 * {
    font-weight: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 
  #___gatsby {
    min-height: 100vh;
  }
  p,a,button {
    font-size: ${(props) => props.theme.typography.sizes.regular};
  }
  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
      margin: 0;
      padding: 0;
      font-weight: normal;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  button {
      cursor: pointer;
      background: none;
      border: none;
      &:focus {
        outline:none;
      }
  }
  a{
    color: inherit;
    text-decoration: none;
  }
  /* Standard Type */
  strong {
    font-family: ${(props) => props.theme.typography.family.bold};
  }
  h1,h2,h3,h4,h5,h6{
    font-size: ${(props) => props.theme.typography.sizes.h3};
    line-height: ${(props) => props.theme.typography.lineHeights.standard};
  }
  p{
    font-size: ${(props) => props.theme.typography.sizes.regular};
    line-height: ${(props) => props.theme.typography.lineHeights.standard};
  }
  .sanity-portble-text ul li {
    position:relative;
    padding-left:20px;
  }
  .sanity-portble-text ul li::marker {
    display:none;
    font-size:0;
  }
  .sanity-portble-text ul li:before {
    content:"•";
    color: ${(props) => props.theme.colors.primary.one};
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1;
  }
`;
